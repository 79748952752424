import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import MouldingWork from "../../assets/mould-base.jpg";
import MillingImg from "../../assets/milling-work.jpg";
import SurfaceGrinding from "../../assets/surface-grinding work.jpg";
import MouldingImg from "../../assets/mould.jpg";
import GrindingImg from "../../assets/grinding.jpg";

import "./Product.css";

const Product = () => {
  const [index, setIndex] = useState(0);

  return (
    <div className="Container">
      <div class="header">
        <h1>Our Products</h1>
      </div>
      <Carousel
        fade
        activeIndex={index}
        onSelect={(selectedIndex, e) => setIndex(selectedIndex)}
      >
        <Carousel.Item>
          <img src={MouldingWork} alt="First slide" />
          <h3>Mould-Work</h3>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies.
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <img src={MillingImg} alt="Second slide" />
          <h3>Milling</h3>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies.
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <img src={SurfaceGrinding} alt="Third slide" />
          <h3>Surface-Grinding</h3>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies.
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <img src={MouldingImg} alt="Fourth slide" />
          <h3>Moulding</h3>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies.
          </p>
        </Carousel.Item>
        <Carousel.Item>
          <img src={GrindingImg} alt="Fifth slide" />
          <h3>Grinding</h3>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies.
          </p>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Product;
