import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faMapMarkerAlt, faPhoneAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const LocationIcon = () => <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} />
const PhoneIcon = () => <FontAwesomeIcon className="me-1" icon={faPhoneAlt} />
const EmailIcon = () => <FontAwesomeIcon className="me-2" icon={faEnvelope} />
const WebsiteIcon = () => <FontAwesomeIcon className="me-2" icon={faGlobe} />
const BarsIcon = () => <FontAwesomeIcon icon={faBars} />
const CloseIcon = () => <FontAwesomeIcon icon={faTimes} />

export { LocationIcon, PhoneIcon, EmailIcon, WebsiteIcon, BarsIcon, CloseIcon };