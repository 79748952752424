import React from "react";
import LogoImg from "../../assets/bg.jpg";
import { Col, Row } from "react-bootstrap";
import MAINIMG from "../../assets/bg.jpg";
import "./Home.css";

const Home = () => {
  return (
    <div className="homeContainer">
      <section className="mainContent">
        <div className="m-left">
          <h2>Our Company</h2>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            Rudra Engineering is counted among most successful and leading
            organizations, that is expert in Surface Grinding, Shapping,
            Milling, Drilling, and Moulding Assemblies. Rudra Engineering has
            built a strong reputation and always remember for its high quality
            services and dependable services. We have a team of expert and
            practical experienced engineers that is always ready to accept new
            challenges and opportunities. Moreover, our innovative & creative
            technique supports us to offer customer specific solutions.
          </p>
        </div>
        <div className="m-right">
            <div className="companyImg">
              <img src={MAINIMG} alt="" />
            </div>
          </div>
        </section>
      <div className="line">
        <hr className="style-line" />
      </div>
        <section className="about-content">
        <div className="a-left">
          <h2>Our Team</h2>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            We are backed by expert team and team leader that is responsible to
            provide all types of solutions whether it’s standard or customized.
            They work closely with the client and in a coordinated manner to
            deliver the solutions at the appropriate time.
          </p>
          <p>Our Team Comprises :</p>
          <ul>
            <li>Experience engineers</li>
            <li>Production team</li>
            <li>Quality analyst</li>
            <li>Research & development team</li>
            <li>Sales & marketing experts</li>
            <li>Skilled labor</li>
          </ul>
        </div>
        <div className="a-right">
          <h2>We Provides</h2>
          <hr className="underLine" />
          <hr className="underLine" />
          <p>
            With more than a decade of practical experience, Rudra Engineering
            Works has been in the field of manufacturing. We strive to maintain
            the quality and customer satisfaction as they are our business
            objective.
          </p>
          <p>factors of Rudra Engineering are :</p>
          <ul>
            <li>Ethical business policies</li>
            <li>Most competitive price</li>
            <li>Customized solutions</li>
            <li>Quality product with maximum buyer satisfaction</li>
            <li>Trusted and certified company</li>
            <li>Technical expertise team</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Home;
