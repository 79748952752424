import React, { useState, useEffect } from "react";
import "./Header.css";
import { CSSTransition } from "react-transition-group";
import LOGO from "../../assets/logo.svg";
import { BarsIcon, CloseIcon } from "../Icons/Icons";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  let location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const menuBar = [
    { id: 1, name: "HOME", url: "/" },
    { id: 2, name: "PRODUCT", url: "/product" },
    { id: 3, name: "SERVICES", url: "/services" },
    { id: 4, name: "CONTACT US", url: "/contact-us" },
  ]

  return (
    <div>
      <header className="Header">
        <Link className="text-start" to="/">
          <img src={LOGO} className="Logo" alt="logo" />
        </Link>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <nav className="Nav">
            {
              menuBar.map(({id, name, url}) => 
              <a style={{ color: url === pathName ? "#F15A24" : "" }} key={id} href={url}>{name}</a>
            )}
          </nav>
        </CSSTransition>
        <button onClick={toggleNav} className="Burger">
          {isNavVisible ? <CloseIcon /> : <BarsIcon />}
        </button>
      </header>
      <div className="pt-100" />
    </div>
  );
};

export default Header;
