import React from "react";
import "./Services.css";
import MAINIMG from "../../assets/bg.jpg";
import Grinding from "../../assets/surface-grinding.jpg";
import Shaping from "../../assets/shaping.png";
import Milling from "../../assets/milling.jpg";
import Drill from "../../assets/drill.jpg";
import Moulding from "../../assets/moulding.jpg";

const Services = () => {
  return (
    <div className="aboutContainer">
      <section className="heading">
        <h1>Services</h1>
      <section className="services">
        <div className="service-box">
          <div className="box">
            <img src={Grinding} alt="" />
            <h3>Surface Grinding</h3>
          </div>
          <div className="box">
            <img src={Shaping} alt="" />
            <h3>Shaping</h3>
          </div>
          <div className="box">
            <img src={Milling} alt="" />
            <h3>Milling</h3>
          </div>
          </div>
          <div className="service-box">
          <div className="box">
            <img src={Drill} alt="" />
            <h3>Drilling</h3>
          </div>
          <div className="box">
            <img src={Moulding} alt="" />
            <h3>Mould Assembly</h3>
          </div>
        </div>
      </section>
    </section>
    </div>
  );
};

export default Services;
