import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import './App.css';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Product from './components/Product/Product';
import Services from './components/Services/Services';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/product" component={Product} exact />
            <Route path="/services" component={Services} exact />
            <Route path="/contact-us" component={ContactUs} exact />
          </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
