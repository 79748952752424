import React from "react";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";
import FACEBOOK from "../../assets/social/facebook.svg";
import INSTAGRAM from "../../assets/social/instagram.svg";
import WHATSAPP from "../../assets/social/whatsapp.svg";
import LOGO from "../../assets/logo.svg";
import { EmailIcon, LocationIcon, PhoneIcon, WebsiteIcon } from "../Icons/Icons";

const solutionFooter = [
  { id: 1, name: "FOR_CORPORATIONS", url: "/corporations" },
  { id: 2, name: "FOR_PRACTITIONERS", url: "/practitioners" },
  { id: 3, name: "HOW_IT_WORKS", url: "/how-it-works" },
  { id: 4, name: "FIND_A_PRACTITIONER_NEAR_YOU", url: "/search-practitioners" },
];

const legalFooter = [
  { id: 1, name: "FAQ", url: "/faq" },
  { id: 2, name: "TERMS_OF_USE", url: "/terms-of-use" },
  { id: 3, name: "PRIVACY_POLICY", url: "/privacy-policy" },
];

const contactFooter = [
  { id: 1, name: "ABOUT_US", url: "/about-us" },
  { id: 2, name: "BLOG", url: "/blog" },
];

const Footer = () => {

    let location = useLocation();
    location = location.pathname;

    return (
        <div className="footer p-3 p-sm-5 p-md-5 p-lg-5">
            <div className="row text-sm-start">
                <div className="col-lg-4">
                    <img src={LOGO} />
                </div>
                <div className="col-lg-3 mt-5 mt-lg-0">
                    <h3>SERVICES</h3>
                    <div className="secondary">
                        <li>Surface Grinding</li>
                        <li>Shaping</li>
                        <li>Milling</li>
                        <li>Drilling</li>
                        <li>Mould Assembly</li>
                    </div>
                </div>
                <div className="col-lg-4 mt-5 mt-lg-0">
                    <h3>CONTACT</h3>
                    <div className="ft-title pt-2">
                    <LocationIcon />ADDRESS</div>
                    <p>10 - Shreedhar Industrial Park, Opp. Shimla Motors, Nr. S.P. Cross Ring Road, Odhav, Ahmedabad - 382415.</p>
                    <div className="ft-title pt-2">
                    <PhoneIcon /> PHONE</div>
                    <a href="tel:+919726655166">+91 97266 55166</a><br/>
                    <a href="tel:+91 9638464545">+91 96384 64545</a>
                    <div className="ft-title pt-2">
                    <EmailIcon />EMAIL ID</div>
                    <a href="mailto:rudraengineering10@gmail.com">rudraengineering10@gmail.com</a>
                    <div className="ft-title pt-2">
                    <WebsiteIcon />WEBSITE</div>
                    <p>www.rudraengineering.co.in</p>
                </div>
            </div>
            <div className="row p-5">
                <div>
                    © {new Date().getFullYear()} Rudra Engineering Inc. All rights reserved.
                </div>
                <div className="d-flex justify-content-center pt-2">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/Rudraengineering.fb"
                        className="p-2"
                    >
                        <img width="40px" src={FACEBOOK}></img>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/Rudraengineering.ig"
                        className="p-2"
                    >
                        <img width="40px" src={INSTAGRAM}></img>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wa.me/919726655166"
                        className="p-2"
                    >
                        <img width="40px" src={WHATSAPP}></img>
                    </a>
                </div>
            </div>
        </div>
    );
};
export default Footer;
