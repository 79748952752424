import React from "react";
import { EmailIcon, LocationIcon, PhoneIcon, WebsiteIcon } from "../Icons/Icons";
// import Map from "../Map/Map";

const ContactUs = () => {
    return(
        <div className="p-5 text-start">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <h1 className="secondary">Contact Information</h1>
                    <div className="ft-title pt-2 d-flex">
                        <LocationIcon />
                        <div className="ms-2">
                            <h5>ADDRESS</h5>
                            <p>10 - Shreedhar Industrial Park, Opp. Shimla Motors, Nr. S.P. Cross Ring Road, Odhav, Ahmedabad - 382415.</p>
                        </div>
                    </div>
                    <div className="ft-title pt-2 d-flex">
                        <PhoneIcon /> 
                        <div className="ms-2">
                            <h5>PHONE</h5>
                            <p>+91 97266 55166</p>
                            <p>+91 96384 64545</p>
                        </div>
                    </div>
                    <div className="ft-title pt-2 d-flex">
                        <EmailIcon /> 
                        <div className="ms-2">
                            <h5>EMAIL ID</h5>
                            <p>rudraengineering10@gmail.com</p>
                        </div>
                    </div>
                    <div className="ft-title pt-2 d-flex">
                        <WebsiteIcon /> 
                        <div className="ms-2">
                            <h5>WEBSITE</h5>
                            <p>www.rudraengineering.co.in</p>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-6 col-md-6 pt-lg-0 pt-md-0 pt-sm-5 pt-5">
                    <Map />
                </div> */}
            </div>
        </div>
    )
}

export default ContactUs;
